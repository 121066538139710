.separator {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 0;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgb(220, 219, 219);
}
.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}